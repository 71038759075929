<template>
  <div v-if="!loading">
    <v-card class="mb-5 banner-card"
            :style="{'background-image': `linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0) 100%),url(${storeData.banner?storeData.banner:defaultBanner})`}">
      <v-row>
        <v-col md="1" cols="2" class="ml-3 text-center">
          <v-avatar
              size="80">
            <v-img :src="storeData.icon?storeData.icon:defaultAvatar"></v-img>
          </v-avatar>
        </v-col>
        <v-col class="pl-0">
          <div class="d-flex">
            <div class="v-card__text white--text font-weight-bold subtitle-1 pl-0 banner-title d-flex">
              <span class="mr-2">{{ storeData.title }}</span>
              <rating card :id-rating="storeData.rating_id" />
            </div>
            <span v-if="$can('any product edit', 'all')" class="deals-storefront">
              <v-btn plain color="white" :to="{name: 'user', params: {id: storeData.user_id, tab: 'store'}}">
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.24998 0.916992L5.07248 1.73949L2.40081 4.41699H11.8333V5.58366H2.40081L5.07831 8.26116L4.24998 9.08366L0.166646 5.00033L4.24998 0.916992Z" fill="#fff"/>
                </svg>
                <span class="ml-2">{{ $t('go to the user account') }}</span>
              </v-btn>
            </span>
            <v-btn
              v-if="isMyStore"
              icon
              class="mr-4 cogwheel-hover"
              :to="{name: 'user-market', params: { tab: 'settings' }}"
            >
              <v-icon
                color="rgba(255, 255, 255, .5)"
              >
                {{ icons.mdiCogOutline }}
              </v-icon>
            </v-btn>

            <!-- <span style="white-space: nowrap" class="mr-2 deals-storefront">{{ $t('go to the user account') }}</span> -->
          </div>
          <v-card-text class="white--text pb-2 pl-0 banner-description">{{ storeData.description }}
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <div>
      <products-table v-if="storeData.id" :loadConnected="loadConnected" :store-id="storeData.id" />
    </div>
  </div>
</template>

<script>
import ProductsTable from './ProductsTable'
import {useRouter} from "@core/utils";
import {getCurrentInstance, ref, computed, onMounted} from "@vue/composition-api";
import { mdiCogOutline } from '@mdi/js';
import axios from "@axios";
import store from "@/store";
import Rating from "./components/Rating";

export default {
  name: "StoreProducts",
  components: {
    ProductsTable,
    Rating
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const loading = ref(true)
    const {router} = useRouter()
    const loadConnected = ref(false)
    const storeData = ref({})
    onMounted(() => {
      loading.value = true
      axios.get('store/' + router.currentRoute.params.slug).then(res => {
        storeData.value = res.data.data
        loading.value = false
      }).catch(() => {
        vm.$notify({
          group: 'deals',
          title: 'Store',
          type: 'error',
          text: vm.$t('something went wrong'),
          max: 5
        })
      })
      store.dispatch('connect/connectedAmzAccounts').then(() => loadConnected.value = true)
    })
    return {
      loadConnected,
      loading,
      storeData,
      isMyStore: computed(() => storeData.value.user_id === store.state.user.userResource.id),
      defaultBanner: require('@/assets/images/app/Avatar.jpeg'),
      defaultAvatar: require('@/assets/images/avatars/1.png'),
      icons: {
        mdiCogOutline
      }
    }
  }
}
</script>
<style scoped lang="scss">
.banner-card {
background-size: cover;
color: white !important;
text-shadow: 0 0 1px #000;
-moz-text-shadow: 0 0 1px #000;
-webkit-text-shadow: 0 0 1px #000;
}
.banner-title{
  font-size: 30px !important;
}
.banner-description{
  font-size: 14px;
}
</style>